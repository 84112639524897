<script setup>
import { AgGridVue } from 'ag-grid-vue3';
import imageCellRenderer from './ImageCellRenderer.vue';
import agGridColumnTypes from '@/lib/ag-grid/column-types';
import defaultColDef from '@/lib/ag-grid/default-column-definitions';
import { computed, ref, watch } from 'vue';
import { trans as $t } from 'laravel-vue-i18n';
import createQueryRequestParams from '@/lib/ag-grid/create-query-request-params';
import StockTableActionButtons from '@/Pages/Stock/Partials/StockTableActionButtons.vue';
import StockCellRenderer from '@/Pages/Stock/Partials/StockCellRenderer.vue';
import {
    columnStateEvents,
    getColumnState,
    gridApi,
    onGridReady,
    stateApplied,
} from '@/lib/ag-grid/save-column-state';
import TableLoading from '@/Components/TableLoading.vue';

const props = defineProps({
    belowMinimumStock: {
        type: Boolean,
        default: false,
    },
});
const emit = defineEmits(['showImage', 'onRequest', 'onShow']);

getColumnState('stock');

const showImage = (image_url) => {
    emit('showImage', image_url);
};

const autoGroupColumnDef = {
    headerName: $t('Maillog SKU'),
    field: 'MaillogSKU',
    type: 'textColumnFilter',
};

const columnDefs = computed(() => [
    {
        headerName: 'SKU',
        field: 'YourSKU',
        type: 'textColumnFilter',
    },
    {
        headerName: $t('Bezeichnung'),
        field: 'Description',
        cellRenderer: imageCellRenderer,
        cellRendererParams: {
            showImage: showImage,
        },
        type: 'textColumnFilter',
    },
    {
        headerName: $t('Batch'),
        field: 'LotNo',
        type: 'textColumnFilter',
    },
    {
        headerName: $t('Expiration Date'),
        field: 'ExpDate',
        type: 'dateColumnFilter',
    },
    {
        headerName: $t('Res. Quantity'),
        type: 'numberColumnFilter',
        valueGetter(params) {
            if (params.node.level === 0) {
                return params.data.reserved_quantity_total;
            }

            return params.data['ReservQty'];
        },
    },
    {
        headerName: $t('Stock'),
        cellRenderer: StockCellRenderer,
        tooltipValueGetter: (params) => {
            if (params.data.components_exist) {
                return null;
            } else {
                return params.value;
            }
        },
        valueGetter(params) {
            if (params.node.level === 0) {
                return (
                    params.data.total_quantity -
                    params.data.reserved_quantity_total
                );
            }

            return params.data['AvailInventory'];
        },
        type: 'numberColumnFilter',
    },
    {
        headerName: $t('Unit'),
        field: 'UnitOfMeasure',
        type: 'textColumnFilter',
        filter: false,
    },
    {
        headerName: $t('Storage Location'),
        field: 'Location',
        type: 'textColumnFilter',
        minWidth: 240,
    },
    {
        headerName: $t('Cost Price'),
        field: 'Direkte_Kosten',
        type: 'numericColumn',
        valueFormatter: costPriceFormatter,
    },
    {
        headerName: $t('Minimum Stock'),
        field: 'MinInventory',
        type: 'numericColumn',
    },
    {
        colId: 'actions',
        cellRenderer: StockTableActionButtons,
        cellClass: 'ag-cell-no-border',
        sortable: false,
        cellRendererParams: {
            onShow: (row) => emit('onShow', row),
        },
        minWidth: 55,
    },
]);
const costPriceFormatter = (params) => {
    if (params.value === 0) {
        return '-';
    }

    return Number(params.value).toLocaleString(undefined, {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
    });
};

const isServerSideGroup = (params) => {
    if (params === undefined) {
        return false;
    }
    return params.BatchLocations?.length > 0;
};
const getServerSideGroupKey = (params) => params['MaillogSKU'];
const rowCount = ref(0);
const dataSource = {
    getRows({ request, success, fail }) {
        if (request.groupKeys.length >= 1) {
            const data = gridApi.value.getRowNode(request.groupKeys[0]).data
                .BatchLocations;

            success({
                rowData: data,
                rowCount: data.length,
            });
        } else {
            const params = createQueryRequestParams(
                request,
                'MaillogSKU',
                2000,
            );

            params.filter['below_minimum_stock'] = props.belowMinimumStock;

            axios
                .get(route('api.maillog.stock.index'), { params })
                .then((response) => {
                    if (response.data.data.length === 0) {
                        gridApi.value.showNoRowsOverlay();
                    } else {
                        gridApi.value.hideOverlay();
                    }

                    rowCount.value = response.data.meta.total;
                    success({
                        rowData: response.data.data,
                        rowCount: response.data.meta.total,
                    });
                })
                .catch(() => fail());

            emit('onRequest', params);
        }
    },
};

const getRowClass = (params) => {
    if (params.node.level === 1) return 'tw-bg-sky-50';
};

const updateItem = (item) => {
    const nodes = [];
    gridApi.value.forEachNode((node) => {
        if (node.data.MaillogSKU === item.number) {
            nodes.push(node);
        }
    });

    if (nodes.length > 0) {
        nodes.forEach((node) => {
            node.setDataValue('MinInventory', item.minimum_stock);
            node.setDataValue('Direkte_Kosten', item.cost_price);
        });
    }
};

const getRowId = (params) => {
    return params.data.BatchLocations !== undefined
        ? params.data.MaillogSKU
        : params.data.MaillogSKU +
              '_' +
              (params.data.LotNo ?? '') +
              '_' +
              (params.data.Location ?? '');
};

watch(
    () => props.belowMinimumStock,
    () => {
        gridApi.value.refreshServerSide({
            purge: true,
        });
    },
);

defineExpose({
    updateItem,
});
</script>

<template>
    <v-card class="tw-h-full">
        <TableLoading :loading="!stateApplied">
            <ag-grid-vue
                class="ag-theme-material tw-h-full"
                :column-types="agGridColumnTypes"
                :default-col-def="defaultColDef"
                :column-defs="columnDefs"
                row-model-type="serverSide"
                :block-load-debounce-millis="200"
                :server-side-datasource="dataSource"
                :enable-range-selection="true"
                :get-row-id="getRowId"
                animate-rows
                tree-data
                :is-server-side-group="isServerSideGroup"
                :auto-group-column-def="autoGroupColumnDef"
                :get-server-side-group-key="getServerSideGroupKey"
                :get-row-class="getRowClass"
                :is-server-side-group-open-by-default="() => true"
                :cache-block-size="2000"
                @grid-ready="onGridReady"
                v-on="columnStateEvents"
            />
        </TableLoading>
    </v-card>
</template>

<style scoped></style>
