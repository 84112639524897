<script setup>
import { AgGridVue } from 'ag-grid-vue3';
import { trans as $t } from 'laravel-vue-i18n';
import columnTypes from '@/lib/ag-grid/column-types.js';
import {
    columnStateEvents,
    getColumnState,
    gridApi,
    onGridReady as onGridReadyState,
    stateApplied,
} from '@/lib/ag-grid/save-column-state.js';
import defaultColDef from '@/lib/ag-grid/default-column-definitions.js';
import { computed, nextTick, ref, watchEffect } from 'vue';
import TableLoading from '@/Components/TableLoading.vue';
import { router, usePage, useRemember } from '@inertiajs/vue3';
import { collect } from 'collect.js';
import TicketActionCellRenderer from '@/Pages/Ticket/Partials/TicketActionCellRenderer.vue';
import { mdiPlus } from '@mdi/js';
import LinkVuetify from '@/Components/LinkVuetify.vue';
import { watchImmediate } from '@vueuse/core';

getColumnState('ticket');
const page = usePage();

const filterModel = useRemember({}, 'ag-grid-filter-model');

const onGridReady = (params) => {
    onGridReadyState(params);
    params.api.setFilterModel(filterModel.value);
};

columnStateEvents.filterChanged = (params) => {
    if (params.source !== 'columnFilter') {
        return;
    }
    filterModel.value = params.api.getFilterModel();
};

const dateFilterParams = {
    comparator: (filterLocalDateAtMidnight, cellValue) => {
        const filterDate = moment(filterLocalDateAtMidnight);
        const cellDate = moment(cellValue).startOf('day');

        if (filterDate.isBefore(cellDate)) {
            return 1;
        }
        if (filterDate.isAfter(cellDate)) {
            return -1;
        }
        return 0;
    },
};
const responsibleNames = computed(() =>
    collect(page.props.lists.responsibles)
        .mapWithKeys((responsible) => [responsible.value, responsible.name])
        .all(),
);

let tempColState = null;
const columnDefs = ref(null);
watchEffect(() => {
    if (gridApi.value && !gridApi.value.isDestroyed()) {
        tempColState = gridApi.value?.getColumnState();
    }

    columnDefs.value = [
        {
            field: 'id',
            headerName: 'ID',
            type: 'numericColumn',
            sortable: true,
            filter: 'agTextColumnFilter',
            floatingFilter: true,
        },
        {
            field: 'special_order.id',
            headerName: 'SAN',
            type: 'numericColumn',
            valueFormatter: (params) => params.value || 'Nein',
            width: 50,
        },
        {
            field: 'special_order.is_completed',
            headerName: 'SAN Abgeschlossen',
            cellRendererSelector: (params) => {
                if (params.data?.special_order !== null) {
                    return {
                        component: 'agCheckboxCellRenderer',
                    };
                }
            },
            cellEditor: 'agCheckboxCellEditor',
            editable: (params) => {
                return params.data.special_order !== null;
            },
        },
        {
            field: 'special_order.billed_at',
            headerName: 'SAN Abgerechnet',
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParams,
            floatingFilter: true,
            valueFormatter: (params) =>
                params.value ? moment(params.value).format('DD.MM.YYYY') : '',
            cellEditor: 'agDateStringCellEditor',
            editable: true,
        },
        {
            field: 'type',
            headerName: $t('Type'),
            filter: 'agSetColumnFilter',
            floatingFilter: true,
            filterParams: {
                values: page.props.lists.types.map((type) => type.name),
            },
        },
        {
            field: 'responsible',
            headerName: $t('Responsible'),
            filter: 'agSetColumnFilter',
            floatingFilter: true,
            valueGetter: (params) =>
                responsibleNames.value[params.data.responsible],
            filterParams: {
                values: page.props.lists.responsibles.map(
                    (responsible) => responsible.name,
                ),
            },
        },
        {
            field: 'status',
            headerName: $t('Status'),
            filter: 'agSetColumnFilter',
            floatingFilter: true,
            editable: true,
            cellEditor: 'agSelectCellEditor',
            cellEditorParams: {
                values: page.props.lists.statuses,
                valueListGap: 10,
            },
        },
        {
            field: 'customer_number',
            headerName: $t('Customer Number'),
            type: 'textColumnFilter',
        },
        {
            field: 'delivery_note_number',
            headerName: $t('Delivery Note Number'),
            type: 'textColumnFilter',
        },
        {
            field: 'external_claims_number',
            headerName: $t('External Claims Number'),
            type: 'textColumnFilter',
        },
        {
            field: 'created_by',
            headerName: $t('Created by'),
            filter: 'agSetColumnFilter',
            floatingFilter: true,
        },
        {
            field: 'created_at',
            headerName: $t('Created at'),
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParams,
            floatingFilter: true,
            valueFormatter: (params) =>
                moment(params.value).format('DD.MM.YYYY'),
        },
        {
            field: 'updated_at',
            headerName: $t('Updated at'),
            filter: 'agDateColumnFilter',
            filterParams: dateFilterParams,
            floatingFilter: true,
            valueFormatter: (params) =>
                moment(params.value).format('DD.MM.YYYY'),
        },
        {
            headerName: $t('Actions'),
            cellRenderer: TicketActionCellRenderer,
            type: 'actionColumn',
            minWidth: 90,
        },
    ];

    if (tempColState) {
        nextTick(() => {
            gridApi.value.applyColumnState({
                state: tempColState,
                applyOrder: false,
            });
        });
    }
});

const rowData = ref(null);
watchImmediate(
    () => page.props.tickets,
    () => {
        rowData.value = page.props.tickets.map((ticket) => {
            if (ticket.special_order) {
                ticket.special_order.is_completed =
                    !!ticket.special_order.completed_at;
            }

            return ticket;
        });
    },
);

const onCellDoubleClicked = (params) => {
    if (
        params.colDef.field === 'status' ||
        params.colDef.field === 'special_order.billed_at' ||
        params.colDef.field === 'special_order.is_completed'
    ) {
        return;
    }
    router.visit(
        route('ticket.show', {
            ticket: params.data.id,
        }),
    );
};

const onCellValueChanged = async (params) => {
    if (params.oldValue === params.newValue) {
        return;
    }

    if (
        params.colDef.field === 'special_order.billed_at' ||
        params.colDef.field === 'special_order.is_completed'
    ) {
        await axios.put(
            route('api.special-order.update', {
                special_order: params.data.special_order.id,
            }),
            {
                billed_at: params.data.special_order.billed_at,
                is_completed: !!params.data.special_order.is_completed,
            },
        );
        return;
    }

    await axios.put(route('api.ticket.update', { ticket: params.data.id }), {
        [params.colDef.field]: params.newValue,
    });
};

const getRowId = (params) => {
    return params.data ? String(params.data.id) : undefined;
};
</script>

<template>
    <v-container fluid>
        <div class="tw-mb-2 tw-flex">
            <v-spacer></v-spacer>
            <LinkVuetify
                v-if="page.props.auth.canCreateTickets"
                :href="route('ticket.create')"
                color="black"
                size="small"
                :prepend-icon="mdiPlus"
                large
                title="{{ __('Create Ticket') }}"
                >{{ $t('Create :name', { name: $t('Ticket') }) }}</LinkVuetify
            >
        </div>
        <v-row>
            <v-col>
                <v-card>
                    <TableLoading :loading="!stateApplied">
                        <ag-grid-vue
                            class="ag-theme-material ag-theme-material-dense tw-h-[calc(100vh-150px)]"
                            :default-col-def="defaultColDef"
                            :column-types="columnTypes"
                            :row-data="rowData"
                            :column-defs="columnDefs"
                            :get-row-id="getRowId"
                            single-click-edit
                            :stop-editing-when-cells-lose-focus="true"
                            :tooltip-show-delay="500"
                            @grid-ready="onGridReady"
                            @cell-double-clicked="onCellDoubleClicked"
                            @cell-value-changed="onCellValueChanged"
                            v-on="columnStateEvents"
                        />
                    </TableLoading>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<style scoped></style>
