<script setup>
import { AgGridVue } from 'ag-grid-vue3';
import { trans as $t } from 'laravel-vue-i18n';
import columnTypes from '@/lib/ag-grid/column-types.js';
import { AG_GRID_LOCALE_DE } from '@ag-grid-community/locale';
import { computed } from 'vue';

const props = defineProps({
    rowData: {
        type: Array,
        required: true,
    },
});

const dateComparator = (filterLocalDateAtMidnight, cellValue) => {
    const filterDate = moment(filterLocalDateAtMidnight);
    const cellDate = moment(cellValue);

    if (cellValue === null) {
        return 0;
    }

    if (cellDate.isBefore(filterDate, 'day')) {
        return -1;
    } else if (cellDate.isAfter(filterDate, 'day')) {
        return 1;
    } else {
        return 0;
    }
};

const defaultColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    floatingFilter: true,
    flex: 1,
};

const groupColumnDef = {
    headerName: $t('Pickliste'),
};
const columnDefs = computed(() => [
    {
        headerName: $t('Pickliste'),
        field: 'pick_list_number',
        rowGroup: props.rowData.some((row) => row.pick_list_number !== null),
        hide: true,
    },
    {
        headerName: $t('Order Number'),
        field: 'order_number',
        type: 'textColumnFilter',
        cellRenderer: (params) => {
            const color = params.data?.highlighted
                ? 'tw-bg-red-500'
                : 'tw-bg-green-600';

            return params.data
                ? `<div class='tw-w-full tw-h-full tw-flex'><div class='${color} tw-shrink-0 tw-w-2 tw-mr-2'></div><div>${params.value}</div></div>`
                : null;
        },
    },
    {
        headerName: $t('Delivery Note Number'),
        field: 'delivery_note_number',
        type: 'textColumnFilter',
    },
    {
        headerName: $t('Requested Delivery Date'),
        field: 'requested_delivery_date',
        type: 'dateColumnFilter',
        filterParams: {
            comparator: dateComparator,
        },
        valueFormatter: (params) =>
            params.value ? moment(params.value).format('DD.MM.YYYY') : null,
    },
    { headerName: $t('Service Level'), field: 'servicelevel' },
    {
        headerName: $t('Shipping Agent'),
        field: 'shipping_agent',
    },
    {
        headerName: $t('Shipping Service'),
        field: 'shipping_agent_service',
    },
    {
        headerName: $t('Pickliste Datum'),
        field: 'pick_list_created_at',
        type: 'dateColumnFilter',
        filterParams: {
            comparator: dateComparator,
        },
        valueFormatter: (params) =>
            params.value ? moment(params.value).format('DD.MM.YYYY') : null,
    },
]);

const getRowClass = (params) => {
    if (!params.data) {
        return;
    }

    let classes = 'tw-cursor-pointer';
    if (params.data?.highlighted) {
        classes += ' tw-text-red-500';
    }

    return classes;
};

const onRowClicked = (params) => {
    window.open(
        route('order.index', {
            open: params.data.order_number,
        }),
    );
};
</script>

<template>
    <ag-grid-vue
        :column-defs="columnDefs"
        :auto-group-column-def="groupColumnDef"
        :locale-text="AG_GRID_LOCALE_DE"
        :default-col-def
        :row-data="rowData"
        class="ag-theme-material ag-theme-material-dense tw-w-full"
        dom-layout="autoHeight"
        :get-row-class
        :column-types="columnTypes"
        group-allow-unbalanced
        group-default-expanded="1"
        @row-clicked="onRowClicked"
    ></ag-grid-vue>
</template>

<style scoped>
:deep(.ag-row-hover:not(.ag-full-width-row)::before, .ag-row-hover) {
    background-color: theme('colors.red.200') !important;
}
</style>
